const Mock = require('mockjs');
export default [
    {
        url: '/focus/list',
        type: 'get',
        response: () => {
            return {
                code: 20000,
                data: [
                    {
                        id: 1,
                        title: '多读书，多看报',
                        img: 'http://mms2.baidu.com/it/u=3773312064,3658588015&fm=253&app=138&f=JPEG?w=500&h=500'
                    },
                    {
                        id: 2,
                        title: '少吃零食',
                        img: 'https://p7.qhimg.com/bdm/768_474_0/t0108ffb8edf707a38c.jpg'
                    },
                    {
                        id: 3,
                        title: '多睡觉',
                        img: 'https://p7.qhimg.com/bdm/768_474_0/t0108ffb8edf707a38c.jpg'
                    }
                ]
            }
        }
    }
]