const Mock = require('mockjs');
export default [
    {
        url: '/category',
        type: 'get',
        response: () => {
            return {
                code: 20000,
                data: [
                    {
                        id: 1,
                        title: 'ShenZhen',
                        href: '/article/shenzhen'
                    },
                    // {
                    //     id: 2,
                    //     title: 'H5',
                    //     href: '/article/h5',
                    // },
                    {
                        id: 3,
                        title: '澳门',
                        href: '/article/macao',
                    },
                    // {
                    //     id: 4,
                    //     title: 'MockJs',
                    //     href: '/article/mockjs',
                    // },
                    // {
                    //     id: 5,
                    //     title: 'DMusic',
                    //     href: '/article/dmusic',
                    // },
                    {
                        id: 6,
                        title: '关于疫情',
                        href: '/article/AboutOutbreak',
                    },
                    
                ]
            }
        }
    }
]