<template>
    <div id="layout-body">
        <router-view></router-view>
        <!-- <back-top></back-top> -->
    </div>
</template>

<script>
    import backTop from '@/components/back-top'
    export default {
        name: "layout-body",
        data(){
          return{
              minHeight: 600
          }
        },
        components: { backTop },
        created() {
            console.log("每一个星球都有一个驱动核心，\n每一种思想都有影响力的种子。\n感受世界的温度，\n年轻的你也能成为改变世界的动力，\n大林哥珍惜你所有的潜力。\n你的潜力，是改变世界的动力！\n\n我知道你除了坏事之外，什么都敢做！！！");
        }
    }
</script>

<style scoped lang="less">
#layout-body{
    /* header = 80px */
    min-height: -moz-calc(100vh - 80px);
    min-height: -webkit-calc(100vh - 80px);
    min-height: calc(100vh - 80px);
}
@media (max-width: 800px) {
    #layout-body{
        /* header = 80px */
        min-height: -moz-calc(100vh + 80px);
        min-height: -webkit-calc(100vh + 80px);
        min-height: calc(100vh + 80px);
    }
}
</style>
