<template>
    <div id="app" v-cloak>
        <Player></Player>
        <loading></loading>
        <layout-header></layout-header>
        <layout-body></layout-body>
        <layout-footer></layout-footer>
        <img src="./assets/images/73c04a9d85343ff127472472790cf9cc.jpg" alt="" id="img_app">
    </div>
</template>
<script>
    import layoutHeader from '@/components/layout/layout-header'
    import layoutBody from '@/components/layout/layout-body'
    import layoutFooter from '@/components/layout/layout-footer'
    import Loading from '@/components/loading'
    import Player from 'zw-player'
    export default {
        name: "app",
        components: {
            layoutHeader,
            layoutBody,
            layoutFooter,
            Loading,
            Player
        }
    }
</script>
<style lang="less">
    #app {
        font-size: 17px;
        color: #6f6f6f;
    }
    #img_app {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -99;
        display: none;
    }
    // 音乐播放器移动端
    @media (max-width: 600px){
        #img_app {
            display: block;
        }
        .music_search_box {
            left: 115px;
            top: 40px;
        }
        .list_close {
            display: none;
        }
    }
</style>
