import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: { title: '首页'}
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue'),
        meta: { title: '关于'}
    },
    {
        path: '/friend',
        name: 'friend',
        component: () => import('../views/Friend.vue'),
        meta: { title: '友链'}
    },
    // 以下是文章路由
    {
        path: '/article/shenzhen',
        name: 'ShenZhen',
        component: () => import('../articles/ShenZhen'),
        meta: { title: '深圳'}
    },
    {
        path: '/article/h5',
        name: 'H5',
        component: () => import('../articles/H5'),
        meta: { title: '大前端'}
    },
    {
        path: '/article/macao',
        name: '澳门',
        component: () => import('../articles/Macao'),
        meta: { title: '澳门'}
    },
    {
        path: '/article/mockjs',
        name: 'MockJs',
        component: () => import('../articles/MockJs'),
        meta: { title: 'MockJs'}
    },
    {
        path: '/article/dmusic',
        name: 'DMusic',
        component: () => import('../articles/DMusic'),
        meta: { title: 'DMusic'}
    },
    {
        path: '/article/aboutoutbreak',
        name: '关于疫情',
        component: () => import('../articles/AboutOutbreak'),
        meta: { title: 'AboutOutbreak'}
    },
    {
        path: '/kuXMusic/index',
        name: '酷X音乐',
        component: () => import('../kuXMusic/index'),
        meta: { title: 'kuXMusicIndex'}
    },
    // 此路由是没有文章的情况下
    {
        path: '/article/noarticle',
        name: '无文章',
        component: () => import('../articles/NoArticle'),
        meta: { title: '抱歉，没有此文章'}
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    let title = 'DALINGE'
    if (to.meta.params){
        title = `${to.meta.title}:${to.params[to.meta.params] || ''} - ${title}`
    }else {
        title = `${to.meta.title} - ${title}`
    }
    document.title = title
    if (to.path !== from.path) {
        store.dispatch('setLoading', true);
    }
    next();
})
router.afterEach((to, from) => {
    // 最多延迟 关闭 loading
    setTimeout(() => {
        store.dispatch('setLoading', false);
    }, 1500)
})
export default router
