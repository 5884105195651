<template>
    <div id="layout-footer">
        <div class="footer-main">
            <!-- <div class="footer-item" v-if="socials.length">
                <div v-for="item in socials" :key="item.id"><a target="_blank" class="out-link" :href="item.href"><i class="iconfont" :class="item.icon"></i>{{item.title}}</a></div>
            </div>
            <div class="footer-item">
                <div style="font-size:17px;font-weight: bold;">资源</div>
                <div><a target="_blank" class="out-link" href="https://segmentfault.com/weekly?utm_source=sf-footer">每周精选</a></div>
                <div><a target="_blank" class="out-link" href="https://www.jmjc.tech/">简明教程</a></div>
                <div><a target="_blank" class="out-link" href="https://daalin.top/">大林哥的官方网站</a></div>
            </div> -->
            <div class="footer-item">
                <div>本站已苟活 {{runTimeInterval}}</div>
                <!-- <div><a target="_blank" class="out-link" href="#">☞后台管理</a></div> -->
            </div>
        </div>
        <div class="copyright" v-if="screenWidth >= 800">
            <span class="shu">Copyright © 2022 by </span>
            <a target="_blank" class="out-link" href="https://daalin.top/">daalin.top</a>
            <span class="shu"> . All rights reserved. </span>
            <span class="shu">|</span>
            <img src="../../assets/images/beian.png" alt="" style="width: 15px;height: 15px;">
            <a target="_blank" class="out-link" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41142602000195"> 豫公网安备 41142602000195号</a>
            <span class="shu">|</span>
            <a target="_blank" class="out-link" href="https://beian.miit.gov.cn/">豫ICP备2022002149号</a>
        </div>
        <div style="text-align: center;" v-else>
            <div>
                <span class="shu">Copyright © 2022 by </span>
                <a target="_blank" class="out-link" href="https://daalin.top/">daalin.top</a>
            </div>
            <div>
                 .All rights reserved.
            </div>
            <div style="display: flex;justify-content: center;align-items: center;">
                <img src="../../assets/images/beian.png" alt="" style="width: 15px;height: 15px;">
                <a target="_blank" class="out-link" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41142602000195"> 豫公网安备 41142602000195号</a>
            </div>
            <div>
                <a target="_blank" class="out-link" href="https://beian.miit.gov.cn/">豫ICP备2022002149号</a>
            </div>
        </div>
    </div>
</template>

<script>
    import sectionTitle from '@/components/section-title'
    export default {
        name: "layout-footer",
        data(){
            return{
                socials: [],
                screenWidth: document.body.clientWidth,
            }
        },
        components:{
            sectionTitle
        },
        computed:{
            runTimeInterval() {
                return this.$store.state.runTimeInterval;
            }
        },
        mounted() {
            window.addEventListener('resize', this.clientWidth)
        },
        beforeDestroy () {
            window.removeEventListener("resize", this.clientWidth)
        },
        methods:{
            clientWidth() {
                this.screenWidth = document.body.clientWidth
            },
            getSocial(){
                this.$store.dispatch('getSocials').then(data =>{
                    this.socials = data
                })
            },
        },
        created(){
            this.getSocial();
            this.$store.dispatch('initComputeTime');
        }
    }
</script>

<style scoped lang="less">
#layout-footer{
    padding: 2%;
    // border-top: 1px solid #F7F7F7;
    font-size: 13px;
    color: #9c9c9c;
    a.out-link:hover{
        color: #ff6d6d;
    }
    .footer-main{
        max-width: 800px;
        margin: 0 auto 20px auto;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        .footer-item{
            // flex: 1;
            & > div:not(:last-child){
                margin-bottom: 10px;
            }
            i{
                margin-right: 10px;
            }
        }
    }
    .copyright{
        display: flex;
        justify-content: center;
        align-items: center;
        .shu {
            margin: 0 5px;
        }
    }
}
    /*****/
    @media (max-width: 800px){
        #layout-footer{
            .footer-main .footer-item:nth-child(3){
                flex: 2;
            }
            color: #666;
        }
    }
    @media (max-width: 600px){
        #layout-footer{
            .footer-main {
                display: block;
                .footer-item{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    flex-wrap: wrap;
                    & > div{
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    /*****/
</style>